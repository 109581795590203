import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/freightlist'

class FreightlistService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    updateMeasurements(id, value, type, is_operator){
        return http.post(`${URL}/measurements/${type}`, {'id': id, 'value': value, 'is_operator': is_operator})
    }
    bulkEdit(voyageId, data){
        return http.post(`${URL}/bulk-edit-freight/${voyageId}`, data)
    }
    updateCheckedStatus(ids, statuses, is_operator){
        return http.post(`${URL}/checked-status`, {'ids': ids, 'statuses': statuses, 'is_operator': is_operator})
    }
    updateDeclinedStatus(ids){
        return http.post(`${URL}/declined-status`, {'ids': ids})
    }
    
    downloadFreightBreakdown(data){
        return http.post(`${URL}/generate-freight-breakdown`, data, {'responseType': 'arraybuffer'})
    }
}

export default new FreightlistService()