<template>
<div v-if="formActive">
    <FormItem id="company" :label="$t('crm.company')" v-model="mainport.office_id" type="livesearch" :service="officeService" :content="mainport.office ? mainport.office.relation.name : ''" 
            :required="true" sizeLabel="3" sizeItem="6" @onchange="setCompareCurrency" :disabled="fromFreightlist && setOnlyExchangeRates" />
    <FormItem id="projects" :label="$t('navigation.mainport_project_region')" v-model="mainport.project" type="select" :options="projectOptions" idField="project_number" v-if="projectOptions.length"
            :required="true" sizeLabel="3" sizeItem="6" @onchange="(e, o, s) => setProjectNumber(s)" :disabled="fromFreightlist && setOnlyExchangeRates" />
    <FormItem id="number" type="number" :label="$t('overview.number')" v-model="mainport.number" :required="true" sizeLabel="3" sizeItem="6" :disabled="fromFreightlist && setOnlyExchangeRates" />
    <FormItem id="type" :label="$t('overview.type')" v-model="mainport.type" type="select" :options="[{id: 'voyage', name: 'NMT Mainport Voyage'}, {id:'manual', name:'Manual'}]" 
             :required="true" :selectDefault="false" sizeLabel="3" sizeItem="6" :disabled="fromFreightlist" />
    <FormItem v-if="mainport.type == 'voyage'" id="voyage" :label="$t('navigation.voyages')" v-model="mainport.voyage_id" type="livesearch" :service="voyageService" sortField="id"
             :content="mainport.voyage ? (mainport.voyage.vessel.name + ' ' + mainport.voyage.number) : ''" nameField="name" sizeLabel="3" sizeItem="6" :required="true" :disabled="fromFreightlist" />

    <FormItem v-if="mainport.type == 'manual'" id="voyage_manual" :label="$t('navigation.voyages')" v-model="mainport.voyage_manual" type="text" sizeLabel="3" sizeItem="6" :required="true" @onchange="setVoyageManual" :disabled="fromFreightlist" />

    <div v-if="loading" class="row mb-2">
        <div class="w-25 col-form-label-sm">{{ $tc('accounting.exchange_rate', 2) }}</div>
        <div class="w-25"> <i class="fa fa-spinner fa-spin"></i> {{$t('form.please_wait')}} </div>
    </div>
    <template v-else>
        <div class="row mb-2" v-for="(rate, index) in mainport.mainport_project_exchange_rates" :key="index">
            <div class="w-25 col-form-label-sm">{{index == 0 ? $tc('accounting.exchange_rate', 2) : ''}}</div>
            <div class="w-25"> <FormItem v-model="rate.currency_id" type="select" :options="filteredCurrencies" :required="!fromFreightlist" :disabledOptions="checkIfAlreadyChosen" /> </div>
            <div class="w-25"> <FormItem v-model="rate.exchange_rate" type="number" :required="!fromFreightlist" /> </div>
            <button type="button" class="btn w-auto p-0" @click="removeExRate(index)"> <i class="fa fa-trash-alt text-danger"></i> </button>
        </div>
        
        <div class="row mt-2">
            <div class="w-25 col-form-label-sm">{{mainport.mainport_project_exchange_rates.length == 0 ? $tc('accounting.exchange_rate', 2) : ''}}</div>
            <button type="button" class="ms-3 btn btn-primary btn-sm w-25" @click="addExRate"><i class="fa fa-plus"></i>&ensp;{{$t('form.add', [$t('accounting.exchange_rate')])}}</button>
        </div>
    </template>
</div>
</template>

<script>
    import voyageService from '@/services/VoyageService';
    import officeService from '@/services/FinanceSettingService';
    import currencyService from "@/services/CurrencyService";
    import store from '@/store/user'
    export default {
        name: 'MainportForm',
        props: ['itemData', 'fromFreightlist', 'currencySuggestions'],
        data() {
            return {
                mainport: {'mainport_project_exchange_rates': []},
                setOnlyExchangeRates: false,
                voyageService: voyageService,
                officeService: officeService,
                compareCurrency: "",
                projectOptions: [],
                currencies: [],
                filteredCurrencies: [],
                submitted: false,
                loading: false,
                invalid: {},
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.mainport = val    
            },
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(){
                let valid = true;

                let required = document.querySelectorAll('#formModal [required]');

                for(let field of required) {
                    if(!field.value || field.value == this.$t('form.select')) {
                        field.classList.add('is-invalid');
                        valid = false;
                    }
                    else{
                        field.classList.remove('is-invalid');
                    }
                }
                if(!valid) alert(this.$t('errors.empty_fields'));
                return valid;
            },
            getData() {
                return this.mainport;
            },
            setData(data) {
                this.mainport = data;
                this.loading = true;
                this.getCurrencies()
            },
            reset(isEditing = false) {
                this.mainport = {
                    'number': '',
                    'type': 'voyage',
                    'voyage_id': '',
                    'voyage_manual': '',
                    'office_id': null,
                    'project': null,
                    'project_region_id': null,
                    'mainport_project_exchange_rates': []
                };
                this.setOnlyExchangeRates = false;
                this.projectOptions = [];
                const officeId = store.getters.getCompanyId;
                if(officeId != 2 && !store.getters.getCompanyHq){
                    this.mainport.office_id = officeId;
                    this.mainport.office = {
                        relation: {name: store.getters.getCompanyName }
                    }
                }
                this.loading = false;
                this.invalid = {}
                this.getCurrencies(!isEditing)
            },
            getCurrencies(setAutoProject = false) {
                currencyService.index().then(response => {
                    this.currencies = response.data;
                    window.defaultCurrency = this.currencies.filter(currency => currency.is_default == 1)[0].id;
                    this.filteredCurrencies = response.data;
                    for(let index in response.data){
                        this.currencies[index].name = this.currencies[index].code;
                        this.filteredCurrencies[index].name = this.filteredCurrencies[index].code;
                    }
                    if(this.mainport.office_id){
                        this.setCompareCurrency(this.mainport.office_id, setAutoProject ? 'auto' : null);
                    }
                }).catch(error => {
                    console.log('error', error)
                })
            },
            setCompareCurrency(id, name = null){
                if(!id) return;
                this.filteredCurrencies = [...this.currencies];
                officeService.getDefaultCurrency(id).then(response => {
                    this.filteredCurrencies = [...this.currencies];
                    if(response.data.id){
                        this.defaultCurrency = response.data;
                        for(let index in this.currencies){
                            this.currencies[index].name = this.currencies[index].code + '/' + this.defaultCurrency.code;
                            this.filteredCurrencies[index].name = this.filteredCurrencies[index].code + '/' + this.defaultCurrency.code;
                        }
                        this.filteredCurrencies = this.filteredCurrencies.filter(curr => curr.code != this.defaultCurrency.code);
                        if(response.data.projects){
                            if(response.data.projects.length == 1){
                                this.mainport.project = response.data.projects[0].project_number;
                                this.mainport.project_region_id = response.data.projects[0].id ? response.data.projects[0].id : null;
                                if(name != null){
                                    this.setProjectNumber(response.data.projects[0]);
                                }
                            }
                            else{
                                this.projectOptions = response.data.projects;
                                if(this.mainport.project === undefined){
                                    let pnumber = null;
                                    if (this.mainport && this.mainport.project_region && this.mainport.project_region.project_number) {
                                        pnumber = this.mainport.project_region.project_number;
                                    } else {
                                        pnumber = this.mainport.number.substring(2, 3);
                                    }
                                    const foundProject = this.projectOptions.find(po => po.project_number == pnumber);
                                    if(foundProject){
                                        this.mainport.project = pnumber;
                                        this.mainport.project_region_id = foundProject.id ? foundProject.id : null;
                                    } else{
                                        this.mainport.project = null;
                                    }
                                }
                            }
                        }
                        else{
                            this.mainport.project = null;
                        }
                    }
                    if(this.currencySuggestions){
                        this.currencySuggestions.forEach(suggestion => {
                            if(suggestion)
                                this.mainport.mainport_project_exchange_rates.push({'currency_id': suggestion, 'exchange_rate': '', 'id': null});
                        });
                    }
                    this.loading = false;
                }).catch(error => {
                    console.log('error', error);
                    this.filteredCurrencies.forEach(curr => {
                        curr.name = curr.code;
                    });
                    this.loading = false;
                })
            },

            setProjectNumber(project){
                if(!project) return;
                this.mainport.number = project.next_number;
                this.mainport.project_region_id = project.id;
            },
            setVoyageManual(){
                let voyageManualSplit = this.mainport.voyage_manual.split(' ');
                voyageManualSplit.forEach((part, index) => {
                    if(part[0] && !part.match(/^[A-Z]+[0-9]+$/)){
                        voyageManualSplit[index] = part[0].toUpperCase() + part.substring(1).toLowerCase();
                    }
                });
                this.mainport.voyage_manual = voyageManualSplit.join(' ');
            },
            
            checkIfAlreadyChosen(item, value){
                if(item.id == value) return false;
                for(let exr of this.mainport.mainport_project_exchange_rates){
                    if(item.id == exr.currency_id){
                        return true;
                    }
                }
                return false;
            },
            addExRate(e){
                this.mainport.mainport_project_exchange_rates.push({'currency_id': null, 'exchange_rate': '', 'id': null});
            },
            removeExRate(index){
                this.mainport.mainport_project_exchange_rates.splice(index, 1);
            }
        }
    }
</script>
